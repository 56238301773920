.drop-zone {
  background: transparent;
  height: 100%;
  width: 100%;
  text-align: center;
  border: 3px dashed #bbbbbb;
  cursor: pointer;
  color: #3a3a3a;
  font-size: 1.5rem;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-video {
  text-decoration: line-through;
  color: rgba(117, 117, 117, 0.68);
}

.checkmark {
  height: 11px;
  width: 11px;
  padding: 2px;
  margin: 0 1px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 25%;
  background: url(../../check-bg.png);
  background-position: 50% 50%;
  border-radius: 19px;
}

.video-metadata > form {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 111px;

  .stitch-toggle {
    align-self: center;
  }
}

.video-metadata .form-control {
  width: 275px;
}

.form-control.is-invalid {
  background-image: none;
  border-color: #e52424;
}

.invalid-feedback {
  color: #e52424;
  font-size: 14px;
}

.drag-handle-item {
  cursor: grab;
  width: 100%;
}

.file-path {
  font-size: 1rem;
}

.parentSection {
  padding: 1.25rem;
}

.list-group-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badge {
  font-size: 1rem;
}

.sortable-container {
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  background-color: #f9f9f9;
}
