html {
  height: 100%;
  background-image: url("https://static1.squarespace.com/static/5c3d682f96e76f4cee7ca4dd/t/5c3d68bb6d2a7340df03012e/1547528406103/kaliber.ai-main-banner.png");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(116, 116, 116, 1);
  font-family: "-apple-system", Helvetica, Arial, sans-serif;
  background-color: transparent;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parentSection {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}

.feedbackSection {
  flex-grow: 0;
}

.fileTree {
  flex-grow: 100;
  overflow-y: scroll;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  height: 70px;

  .logo-area {
    height: 40px;
  }
}

.right-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.user-info-container,
.s3-bucket-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

form {
  width: 100%;
}

li {
  list-style-type: none;
}

ul {
  padding: 0;
}

details ul {
  padding: 1rem;
}

b {
  color: rgba(116, 116, 116, 1);
}

.drag-hover {
  background: ghostwhite;
}

#logo {
  margin-bottom: 30px;
  height: 40px;
}

.box {
  padding: 25px;
  background-color: white;
  border-radius: 10px;
}

.custom-container {
  width: 90%;
  height: 90%;
  display: flex;
  flex-flow: column;
}

.submit {
  font-size: 1.3em;
  line-height: 2.3rem;
  height: 2.5rem;

  width: 130px;
  margin: 10px 0;
}

progress {
  margin: 2px;
  width: 250px;
}

.progress {
  width: 100%;
}

.eta {
  display: block;
  text-align: right;
  font-size: 0.8rem;
}

.spinner {
  text-align: center;
  margin: auto;
}
